import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const IndustryPage = () => (
  <Layout>
    <SEO title="Industry" description="" lang="en" meta={[]} />

    {/* Industrial Section */}
    <section className="africa-industrial focus-page">
      <h2 className="text-center mb-3">Industrial</h2>
      <div className="section-img" />
    </section>
  </Layout>
)

export default IndustryPage
